.levelItem {
	display: flex;
	flex-direction: column;
	align-items: center;

	.levelName {
		background: #f8f8f8;
		/* Strokes/Primario */

		border: 1px solid #ced4da;
		border-radius: 4px;
		padding: 8px 16px;
	}
	.levelOptionDescription {
		padding: 8px 16px;
		border: 1px solid #ced4da;
		border-radius: 4px;
		color: #f04a5e;
	}
}
.containerLevelsPreview {
	display: flex;
	padding: 16px;
	flex-wrap: nowrap;
	overflow-x: auto;
}
