@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.selected-items {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 8px 24px;
	border-bottom: 0.5px solid #ced4da;
	flex: none;
	width: 100%;

	.items-header {
		display: flex;
		justify-content: space-between;
		width: 100%;

		.optionTitle {
			font-size: 12px;
			color: #f04a5e;
		}
		.optionDetail {
			color: #afafaf;
			font-size: 12px;
		}
	}

	ul {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 5px;
	}

	.list-horizontal li {
		padding: 4px 8px;
		background: #f8f8f8;
		display: inline-flex;
		align-items: center;
		border: 1px solid #ecf3fa;
		border-radius: 4px;
		flex-wrap: nowrap;
		white-space: nowrap;
		font-weight: 500;
		font-size: 10px;
		line-height: 12px;
		> :last-child {
			margin-left: 10px;
			color: $primary-color;
			cursor: pointer;
		}
	}
	.list-horizontal li:before {
		content: none;
		color: #ced4da;
		font-size: 20px;
	}

	.list-horizontal li:first-child:before {
		content: none;
	}

	.item-container {
		display: flex;
		min-width: 0px;
		background-color: #f8f8f8;
		border-radius: 2px;
		margin: 2px;
		box-sizing: border-box;
		border: 1px solid #ecf3fa;
		border-radius: 4px;
	}
}
