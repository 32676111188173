.elector-title-row {
	background-color: rgba(#f04a5e, 0.06);
	border-radius: 10px;
	color: #f04a5e;
	font-size: 16px;
	font-weight: 500;
	line-height: 19px;
	margin: 0 4px;
	padding: 15px 17px;
}

.elector-data-container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	.elector-data-row {
		display: flex;
		flex-direction: row;
		flex: 1;
		gap: 8px;
		padding: 0px 12px;
		width: 100%;

		.elector-data-column {
			display: flex;
			flex-direction: column;
			gap: 4px;
			width: 100%;

			// &.fechas {
			// 	flex-basis: 25%;
			// }

			// &.domicilio {
			// 	flex-basis: 50%;
			// }

			.elector-data-title {
				font-size: 12px;
				font-weight: 400;
				line-height: 14px;
			}
		}

		.elector-data-column-button {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 20%;
		}
	}
}

.elector-footer {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-top: 24px;
}

.social-logo {
	background-color: #fff;
	border-radius: 0.25rem 0px 0px 0.25rem;
	border-right: 0px;
	padding: 4px 0px 4px 8px;
}

.social-input {
	border-left: 0px;
	border-radius: 0px 0.25rem 0.25rem 0px;
}

.required-span {
	align-content: center;
	color: #f04a5e;
	display: flex;
	font-size: 12px;
	font-weight: 400;
	gap: 4px;
}

.required-label {
	color: #f04a5e;
	font-size: 14px;
	font-weight: 400;
}

.curp-validation {
	background-color: #fff;
	height: auto;
	width: auto;

	.li {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 12px;
		justify-content: flex-start;

		.iconos {
			align-items: center;
			border-radius: 50%;
			display: flex;
			height: 20px;
			justify-content: center;
			width: 20px;

			&.error {
				background-color: #dc3545;
			}

			&.succes {
				background-color: #198754;
			}
		}

		span {
			.error {
				color: #dc3545;
			}

			.success {
				color: #198754;
			}
		}
	}
}
