
.titleContainer{
  border: 1px solid #ECF3FA;
  border-bottom: 0;
  padding: 16px 24px;
}
.logosContainer {
	padding: 24px;
  border: 1px solid #ECF3FA;
	.social-media {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 3px;
		background: #f8f8f8;
		padding: 16px 30px;

		.social-logo {
			width: 48px;
			height: auto;
		}
		.social-name {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: #afafaf;
		}
		.social-username {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			color: #333333;
		}
	}
}
