.imgAvatarSmall{
  width: 60px;
  height: 360px8px;
  border-radius: 50%;
}
.checkRounded{
  display: flex;
  align-items: center;

  >span{
    border-radius: 50%;
    padding: 2px;
    display: inline-flex;
    align-items: center;
  }
}