.importer {
    border: 2px dashed #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2em;
    padding-bottom: 2em;
    b {
        color: #06976A;
        text-decoration: underline;
    }
    span {
        color: #d3d3d3;
    }
}
  
  #input-file-upload {
    display: none;
  }
  
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
