.page-voter-details {
	.main-data-container {
		padding: 14px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		flex-wrap: wrap;

		.avatar-name {
			display: flex;
			align-items: center;
			img {
				width: 48px;
				height: 48px;
				border-radius: 50%;
			}
			h2 {
				font-size: 24px;
				margin-left: 16px;
				color: #333333; // pending note  -> create variable for this
			}
		}

		.main-data-list {
			padding: 4px 8px;
			background: white; // pending note  -> create variable for this
			border-radius: 27px;
			color: $primary-color-brand;
			font-weight: 500;
			@media only screen and (max-width: 767px) {
			}

			ul {
				display: flex;
				align-items: center;
			}

			.list-horizontal li {
				display: inline-flex;
				align-items: center;
				padding-left: 4px;
				padding-right: 4px;
			}
			.list-horizontal li:before {
				content: '\00a0\2022\00a0\00a0';
				color: #ced4da;
				font-size: 20px;
			}

			.list-horizontal li:first-child:before {
				content: none;
			}
		}
	}

	.details-view-container {
		background-color: white;
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		.personal-info {
			flex: 1;
		}
		.election-info {
			flex: 2;
		}
	}

	.custom-title-pyramid {
		color: $primary-color-brand;

		h3 {
			font-size: 16px;
		}

		margin-bottom: 8px;
	}

	.custom-detail-card {
		background-color: white;
		display: flex;
		flex-direction: column;
		border: 2px solid #ecf3fa;
		border-radius: 4px;
		// padding: 0 24px;

		&.horizontal {
			flex-direction: row;
			width: 100%;

			> div {
				padding: 12px 24px;
				border-right: 2px solid #ecf3fa;
				width: 100%;

				&:last-child {
					border-right: 0;
				}
			}
		}

		> div {
			padding: 12px 24px;
			border-bottom: 2px solid #ecf3fa;
			width: 100%;

			&:last-child {
				border-bottom: 0;
			}
		}

		.custom-title {
			color: $primary-color-brand;

			&.custom-buttons {
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				div {
					display: flex;
					flex-direction: row;
					gap: 4px;
					justify-content: flex-end;
					align-items: center;
				}
			}

			h3 {
				font-size: 16px;
			}
		}
		.item-title {
			margin: 0;
			color: #afafaf;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
		}
		.item-title-bold {
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 10px;
		}
		.item-description {
			margin: 0;
			color: #333333;
			font-weight: 500;
			white-space: break-spaces;
			overflow-wrap: anywhere;
		}
	}

	.custom-detail-card.flexInline {
		margin-bottom: 16px;
		// background-color: #fff1f1;
		display: grid;
		grid-template-columns: 50% 50%;
		> div {
			border-bottom: none;
		}
	}

	@media only screen and (max-width: 767px) {
	}
}
