.create-event-title {
	background-color: rgba($primary-color, 0.06);
	color: $primary-color;
	padding: 15px 17px;
	border-radius: 10px;
	margin: 0 4px;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
}

.applied-sections-header {
	border-radius: 10px 10px 0 0;
	border-width: 1px;
	background-color: $light-background;
	padding: 1% 0%;
}

.sections-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	background: #fcfcfc;
	isolation: isolate;

	.sections-item {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 24px;
		border-bottom: 0.5px solid #ced4da;
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;
		z-index: 1;
		color: $primary-color;

		&.is-selected {
			background-color: rgba($primary-color, 0.06);
		}
	}
}

// .selected-sections {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: flex-start;
// 	padding: 0px;
// 	background: #fcfcfc;
// 	border-radius: 4px 4px 0 0;

// 	.selected-sections-header {
// 		box-sizing: border-box;
// 		display: flex;
// 		flex-direction: row;
// 		justify-content: space-between;
// 		align-items: center;
// 		padding: 8px 16px;
// 		background: #f8f8f8;
// 		border-bottom: 1px solid #ced4da;
// 		flex: none;
// 		order: 0;
// 		flex-grow: 0;
// 		width: 100%;
// 		border-radius: 4px 4px 0 0;
// 		height: 35px;

// 		span {
// 			font-weight: 500;
// 			font-size: 16px;
// 			line-height: 19px;

// 			color: $primary-color;
// 		}
// 	}

// 	.selected-actions-body {
// 		width: 100%;
// 		height: 100%;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: flex-start;
// 		padding: 0px;
// 		flex: 1 1 auto;
// 		order: 1;
// 		flex-grow: 1;
// 		background-color: #fcfcfc;

// 		.selected-items {
// 			box-sizing: border-box;
// 			display: flex;
// 			flex-direction: column;
// 			align-items: flex-start;
// 			padding: 8px 24px;
// 			border-bottom: 0.5px solid #ced4da;
// 			flex: none;
// 			width: 100%;

// 			.items-header {
// 				color: $primary-color;
// 			}

// 			.item-container {
// 				display: flex;
// 				min-width: 0px;
// 				background-color: #f8f8f8;
// 				border-radius: 2px;
// 				margin: 2px;
// 				box-sizing: border-box;
// 				border: 1px solid #ecf3fa;
// 				border-radius: 4px;
// 			}
// 		}
// 	}
// }

div {
	button {
		&.increment-decrement-voters {
			background: transparent !important;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #ced4da;
			color: $primary-color;

			&.increment {
				border-radius: 0px 6px 6px 0px !important;
				border-left: 0;
			}

			&.decrement {
				border-radius: 6px 0px 0px 6px !important;
				border-right: 0;
			}
		}
	}

	/* Works for Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Works for Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	input {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
	}
}
