@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

div.plusMinusComponent {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 0;
  flex-wrap: nowrap;

  .change-number-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    border: 0;
    border-radius: 6px;
    padding: 0 12px;

    svg {
      color: $primary-color-brand;
    }
  }

  .inputNumber {
    border: 0 !important;
    text-align: center;
  }
}
