.custom-switch {
	z-index: 0;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
	border-color: #3bc279 !important;
	background-color: #3bc279 !important;
}

.custom-switch .custom-control-input:checked:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 0.2rem #3bc27966 !important;
}

.survey-question-type-title {
	color: $primary-color;
	font-size: 16px;
	font-weight: 500;
}
