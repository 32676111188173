.logo-user {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
}

.code-input {
	border: 0px;
	border-bottom: 1px solid black;
	width: 100%;
	padding: 20px;
	text-align: center;
	font-size: 20px;
	line-height: 1;
}

.code-input-container {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(6, 1fr);
	margin-bottom: 10px;
}
