.recruited-logs-header {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	gap: 8px;

	width: 100%;
	height: 100px;

	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	.filter-recruited-logs-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		height: 100%;

		div {
			display: flex;
			align-items: center;

			.headers-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-evenly;

				padding: 8px 0.5vh;

				height: 33px;

				background: $primary-color-brand;
				border-radius: 6px;
			}
		}
	}
}