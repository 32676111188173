.rdt_Table {
	display: flex; /* Enable flexbox layout */
	width: 100%; /* Adjust width as needed */
}

.rdt_TableRow,
.rdt_TableHeader,
.rdt_TableHeadRow {
	display: flex; /* Apply flexbox to rows and header rows */
}

.rdt_TableCol {
	flex: 1 0 auto; /* Allow content to grow within remaining space */
}

.rdt_TableCol:first-child,
.rdt_TableCol:last-child {
	flex: 0 0 auto; /* Fix width for first and last columns */
	width: 150px; /* Adjust width as needed */
}
