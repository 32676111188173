@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.answered-number-badge {
	display: flex;
	padding: 4px 8px;
	justify-content: center;
	align-items: center;

	border-radius: 4px;
	border: 1px solid #ecf3fa;
	background: #ecf3fa;

	margin-right: 8px;
}

.categories-tab-content {
	background: white;
	padding: 26px 16px;
	height: 100%;
}

.custom-detail-card {
	background-color: white;
	display: flex;
	flex-direction: column;
	border: 2px solid #ecf3fa;
	border-radius: 4px;

	> div {
		padding: 12px 24px;
		border-bottom: 2px solid #ecf3fa;

		&:last-child {
			border-bottom: 0;
		}
	}

	.custom-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $primary-color-brand;

		h3 {
			font-size: 16px;
		}
	}
	.subcategory-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.item-title {
		margin: 0;
		color: #afafaf;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		gap: 10px;
	}
	.item-description {
		margin: 0;
		color: #333333;
		font-weight: 500;
		white-space: break-spaces;
		overflow-wrap: anywhere;
	}
}

.download-button {
	display: inline-block;
	font-weight: 400;
	color: #3bc279;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	border-radius: 0.25rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
	background-color: #e8fef2 !important;
	border-color: #3bc279 !important;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	&:disabled {
		background-color: #afafaf !important;
		color: #333333 !important;
		border-color: #333333 !important;
	}
}

.download-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 0.375rem 0.75rem;
	border-radius: 0.25rem;

	-webkit-user-select: none;
	user-select: none;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;

	background-color: #e8fef2 !important;
	border-color: #3bc279 !important;
	color: #3bc279;

	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.survey-detail-card {
	background-color: white;
	display: flex;
	flex-direction: column;
	border: 2px solid #ecf3fa;
	border-radius: 4px;

	> div {
		padding: 12px 24px;
		border-bottom: 2px solid #ecf3fa;

		&:last-child {
			border-bottom: 0;
		}
	}

	.survey-category-card {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		&.selected {
			background-color: #fff1f1;
		}
	}

	.survey-subcategory-card {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		gap: 8px;

		&.selected {
			background-color: #fff1f1;
		}

		.subcategory-info {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.subcategory-span {
				color: #afafaf;
				font-size: 12px;
				font-weight: 400;
			}

			.subcategory-name {
				color: #333;
				font-size: 12px;
				font-weight: 500;
			}
		}
	}
}

.category-subcategory-info {
	.rdt_Table {
		border: 1px solid #ecf3fa;
		border-radius: 12px;
		padding: 0;
	}

	.rdt_TableCell,
	.rdt_TableCol {
		border-right: 1px solid #ecf3fa;

		&:last-child {
			border-right: 0;
		}
	}

	.rdt_TableHad {
		border: 1px solid red;
	}
}

.category-subcategory-info-title {
	color: #f04a5e;
	font-size: 16px;
	font-weight: 500;
	text-overflow: ellipsis;
}

.category-subcategory-info-cell {
	color: #333;
	font-size: 12px;
	font-weight: 500;
}
