.pyramid-header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.tabs-container {
		&.nav-tabs {
			border-bottom: 3px solid #fff1f1;
			display: flex;
			overflow-x: auto;
			overflow-y: hidden;
			flex-wrap: nowrap;

			.nav-item {
				color: #afafaf;
				padding: 20px;
				font-size: 1rem;
				font-weight: 500;
				border: none;
				white-space: nowrap;
				text-overflow: ellipsis;
				cursor: pointer;

				&.active {
					color: $primary-color-brand;
					border-bottom: 3px solid $primary-color-brand;
				}
			}
		}
	}
}
