.week-picker-display {
	width: 200px;
	height: 33px;
	color: #454545;
	position: relative;
	border-radius: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #c3c0cc;
	transition: 0.5s ease;
	padding: 0 1rem;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
}

.week-picker-display p {
	margin: 0;
}

.week-picker-options {
	padding: 8px;
	position: absolute;
	top: 105%;
	left: 50%;
	transform: translateX(-50%);
	width: 350px; /* Ajusta este valor según el ancho deseado */
	border-radius: 5px;
	border: 1px solid #c3c0cc;
	display: flex;
	// overflow: hidden;
	background: white;
	z-index: 99;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.title-week {
	padding: 0.8rem;
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-size: 14px;
}

.numbers-container {
	border-top: 1px solid gainsboro;
	width: 100%;
	display: flex;
	flex-direction: row;
	gap: 0;
	justify-content: center;
	align-items: center;
	// display: grid;
	// grid-template-columns: repeat(7, 1fr); /* 7 columnas de ancho igual */
	// grid-auto-rows: minmax(30px, 30px); /* Altura de filas ajustable */
}

.single-number {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px; /* Ajusta el espaciado interno según sea necesario */
	width: 100%;
	font-size: 10px;

	&.day {
		font-size: 12px;
		background: none;
		color: #626068;
	}
}

.selected-week {
	background-color: #f04a5e;
	color: white;
}

.selected-week:first-child {
	border-radius: 10px 0px 0px 10px;
}

.selected-week:last-child {
	border-radius: 0px 10px 10px 0px;
}

.other-month {
	background: none;
	color: #c3c0cc;
}

.arrow-container {
	cursor: pointer;
	width: 25px;
	height: 25px;
	transition: 0.2s ease-in;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}

.arrow-container svg {
	stroke: #454545;
}

.arrow-container:hover {
	background-color: #f04a5e;
	color: white;
	border-radius: 15%;
}

.arrow-container:hover svg {
	stroke: #fff;
	fill: #fff;
}

.other-month {
	background: none;
	color: #c3c0cc;
}
