.rating {
	position: relative;
	display: flex;
	margin: 10px 0px;
	flex-direction: row-reverse;
	width: 100%;

	input {
		position: relative;
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		-webkit-appearance: none;
		appearance: none;
		overflow: hidden;
	}

	input::before {
		content: '\f005';
		position: absolute;
		font-family: fontAwesome;
		font-size: 1.5rem;
		position: absolute;
		left: 0.65rem;
		color: red;
		transition: 0.5s;
	}

	input:nth-child(2n + 1)::before {
		right: 0.65rem;
		color: blue;
		left: inherit;
	}

	input:hover ~ input::before,
	input:hover::before {
		color: #ffd91c;
	}
}
