.events-header {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	gap: 8px;

	width: 100%;
	height: 100px;

	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	.filter-events-header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		height: 100%;

		div {
			display: flex;
			align-items: center;

			.headers-button {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-evenly;

				padding: 8px 0.5vh;

				height: 33px;

				background: $primary-color-brand;
				border-radius: 6px;

				span {
					font-weight: 400;
					font-size: 13px;
					line-height: 17px;
				}
			}

			.search-bar {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0px;

				input {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0px 16px;
					height: 33px;

					border-radius: 6px 0px 0px 6px;
				}

				.search-elector-button {
					background: $primary-color-brand;
					color: $white;
					border-radius: 0px 6px 6px 0px;
					height: 33px;
				}
			}
		}
	}

	.header-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;

		.filter-button {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			padding: 8px 12px;

			width: 100%;
			height: 33px;
		}

		.search-group-button {
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 0px;

			width: 270px;
			height: 34px;
		}

		.create-event-button {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			padding: 8px 12px;

			width: 100%;
			height: 33px;
		}
	}
}

.event-status {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;

	border-radius: 4px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;

	&.not-started {
		background: #c5f7dd;
		border: 1px solid #ecf3fa;
		color: #3bc279;
	}

	&.finished {
		background: #fff1f1;
		border: 1px solid #ecf3fa;
		color: #f04a5e;
	}

	&.in-progress {
		background: #fffce6;
		border: 1px solid #ecf3fa;
		color: #c8a800;
	}
}

.events-filter-modal {
	width: 500px;

	label {
		font-family: 'Rubik';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
	}
}
