.not-found-container {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	background-size: cover;
	background-position: center;
	padding: 30px 0;
	gap: 36px;
}
