@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.filterSmsContainer {
	.header-options {
		display: flex;
		gap: 8px;
		max-height: 35px;

		.filterButton {
			white-space: nowrap;
		}
		.search-bar {
			display: flex;
			align-items: center;
			position: relative;
      flex-wrap: nowrap;
			input {
				height: 100%;
        padding-left: 40px !important;
			}
			svg {
				position: absolute;
				z-index: 99;
				left: 14px;
			}
			button {
				border-radius: 0px 6px 6px 0px;
			}
		}
	}
}

.container-textArea {
	position: relative;

	label {
		font-size: 1rem;
		font-weight: 500;
	}
	.text-area-options {
		position: absolute;
		bottom: 10px;
		right: 10px;
		button {
			font-size: 12px;
		}
	}
}

// .phone-preview {
// 	margin-top: 10px;
// 	position: relative;
// 	display: flex;
// 	flex-direction: column;

// 	.message-template-preview {
// 		position: absolute;
// 		top: 23px;
// 		align-items: center;
// 		width: 100%;
// 		padding-left: 45px;

// 		.message-date, .message-status {
// 			padding-right: 35px;
// 			text-align: end;
//       margin: 0;
//       margin-bottom: 8px;
// 		}
// 		.message-text {
// 			width: 80%;
// 			box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
// 			border-radius: 20px;
// 			padding: 8px 16px;
//       margin-left: auto;
//       margin-right: 35px;
//       margin-bottom: 3px;
// 		}
// 	}
// }
