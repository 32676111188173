.militancy-info-header {
	width: 100%;
	color: $primary-color;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	border-bottom: 1px solid #ecf3fa;
}

.card-body {
	.militancy-info-line {
		padding-left: 1em;
		padding-right: 1em;
		border-bottom: 1px solid #ecf3fa;
		margin-bottom: 4px;

		.button-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}

		.militancy-info-titles {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			align-self: stretch;
			color: #afafaf;
			margin-bottom: 4px;

			.badge {
				background-color: rgba($warning-color, 0.2);
				color: $warning-color;
			}
		}
	}
}
