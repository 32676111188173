@import '../../../brand/index.scss';

.electores-header {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	gap: 8px;

	width: 100%;
	height: 100px;

	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	.filter-elector-headers {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 100%;

		div {
			display: flex;
			align-items: center;

			.filter-button {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				justify-content: space-between;

				width: 200px;
				height: 33px;

				background: $primary-color-brand;
				border-radius: 6px;
			}

			.search-bar {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0px;

				input {
					display: flex;
					flex-direction: row;
					align-items: center;
					padding: 0px 16px;
					height: 33px;

					border-radius: 6px 0px 0px 6px;
				}

				.search-elector-button {
					background: $primary-color-brand;
					color: $white;
					border-radius: 0px 6px 6px 0px;
					height: 33px;
				}
			}
		}
	}
}

.filter-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	// margin: 20px 0;

	.input-content {
		display: flex;
		flex: 1;
		> div {
			display: flex;
			width: 100%;
			padding-left: 30px;
		}
		input {
			border-top-right-radius: unset;
			border-bottom-right-radius: unset;
			width: 100%;
			-webkit-align-items: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			background-color: hsl(0, 0%, 100%);
			border-color: hsl(0, 0%, 80%);
			border-radius: 4px;
			border-style: solid;
			border-width: 1px;
			cursor: default;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-flex-wrap: wrap;
			-webkit-flex-wrap: wrap;
			-ms-flex-wrap: wrap;
			flex-wrap: wrap;
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			min-height: 38px;
			outline: 0 !important;
			position: relative;
			-webkit-transition: all 100ms;
			transition: all 100ms;
			box-sizing: border-box;
			padding: 0 10px;
		}
		button {
			border-top-left-radius: unset;
			border-bottom-left-radius: unset;
		}
	}

	.button-content {
		height: 38px;
	}
}
.cell-avatar {
	display: flex;
	align-items: center;
	width: 100%;
	margin-left: 15px;
	// justify-content: center;
	img {
		width: 32px;
		height: 32px;
		border-radius: 50%;
	}
	p {
		text-transform: capitalize;
	}
}
.cell-gender {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #fff1f1; // set a variable
	color: $primary-color-brand;
}
.actionButton {
	font-size: 32px;
	background: none !important;
	border: none;
	color: #ecf3fa; //Pending note -> see why this color throw an error $light-gray;
	&:hover {
		font-size: 36;
		color: $primary-color-brand !important;
	}
}

.actionIconButton {
	font-size: 32px;
	background: none !important;
	border: none;
	color: #ecf3fa; //Pending note -> see why this color throw an error $light-gray;
	&:hover {
		font-size: 36px;
		color: $primary-color-brand !important;
		cursor: pointer;
	}
}

.electors-filter-modal {
	width: 500px;
	input[type='number'] {
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		appearance: textfield;
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}
}

/* .react-date-picker__wrapper {
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;

	padding: 8px 15px;

	width: 100%;
	height: 33px;

	border: 1px solid #ced4da;
	border-radius: 6px;

	.react-date-picker__inputGroup {
		input,
		span {
			font-family: 'Rubik';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 17px;

			color: #afafaf;
		}
	}
} */

input:checked {
	background-color: $primary-color-brand;
}

.militancy-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 4px;

	.militancy-info {
		stroke: #afafaf;
	}
}
