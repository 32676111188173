.event-info-header {
	width: 100%;
	color: $primary-color;
	border-bottom: 1px solid #ecf3fa;
}

.card-body {
	.event-info-line {
		padding-left: 1em;
		padding-right: 1em;
		border-bottom: 1px solid #ecf3fa;
		margin-bottom: 4px;

		.event-info-titles {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			align-self: stretch;
			color: #afafaf;
			margin-bottom: 4px;

			.badge {
				background-color: rgba($warning-color, 0.2);
				color: $warning-color;
			}
		}
	}
}

.info-stats {
	width: 100%;

	.info-card {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		height: 100%;
		border-radius: 8px;
		padding: 24px;

		&.assistence {
			background: #e8fef2;
			border: 1px solid #ecf3fa;

			.badge-icon {
				background: #3bc279 !important;
				width: 40px;
				height: 40px;
				padding: 4px;
				border-radius: 6px;
			}

			.stats-number {
				font-style: normal;
				font-weight: 500;
				font-size: 28px;
				line-height: 33px;
				color: #3bc279 !important;
			}
		}

		&.compliance {
			background: #fffce6;
			border: 1px solid #ecf3fa;

			.badge-icon {
				background: #c8a800 !important;
				width: 40px;
				height: 40px;
				padding: 4px;
				border-radius: 6px;
			}

			.stats-number {
				font-style: normal;
				font-weight: 500;
				font-size: 28px;
				line-height: 33px;
				color: #c8a800 !important;
			}
		}

		&.right-section {
			background: #f6f1f5;
			border: 1px solid #ecf3fa;

			.badge-icon {
				background: #ce0063 !important;
				width: 40px;
				height: 40px;
				padding: 4px;
				border-radius: 6px;
			}

			.stats-number {
				font-style: normal;
				font-weight: 500;
				font-size: 28px;
				line-height: 33px;
				color: #ce0063 !important;
			}
		}

		.stats-text {
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 14px;
			color: #afafaf;
			margin-top: 12px;
			margin-bottom: 12px;
		}
	}
}

.atendee-image {
	width: 32px !important;
	height: 32px !important;
	border-radius: 50px !important;
}

.militancy-image {
	width: 32px !important;
	height: 32px !important;
}

.rounded-badge {
	display: flex;
	width: 24px;
	height: 24px;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	background-color: rgba($primary-color, 0.1);
	color: $primary-color;
}

.rdt_Table {
	border: 0;
	padding: 0 20px;
}

.edit-event-info {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	height: 38px;
	border-radius: 6px;

	::placeholder {
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
	}
}
