.sms-info {
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;

	.title {
		font-size: 12px;
		color: #b4b4b4;
	}

	.info {
		font-size: 12px;
		font-weight: 600;
		color: #4d4d4d;
	}
}

.territories-info {
	font-size: 14px;
	color: #f04a5e;
	text-align: end;
}
