@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.selected-sections {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	background: #fcfcfc;
	border-radius: 4px 4px 0 0;

	.selected-sections-header {
		box-sizing: border-box;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 16px;
		background: #f8f8f8;
		border-bottom: 1px solid #ced4da;
		flex: none;
		order: 0;
		flex-grow: 0;
		width: 100%;
		border-radius: 4px 4px 0 0;
		height: 35px;

		span {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;

			color: $primary-color;
		}
	}

	.selected-actions-body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		background-color: #fcfcfc;

		.selected-items {
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 8px 24px;
			border-bottom: 0.5px solid #ced4da;
			flex: none;
			width: 100%;

			.items-header {
				display: flex;
				justify-content: space-between;
				width: 100%;

				.optionTitle {
					font-size: 14px;
					color: #f04a5e;
				}
				.optionDetail {
					color: #afafaf;
					font-size: 12px;
				}
			}

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				gap: 5px;
			}

			.list-horizontal li {
				padding: 4px 8px;
				background: #f8f8f8;
				display: inline-flex;
				align-items: center;
				border: 1px solid #ecf3fa;
				border-radius: 4px;
				flex-wrap: nowrap;
				white-space: nowrap;
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
				box-shadow: 2px 2px rgba(191, 213, 248, 0.6);
				> :last-child {
					margin-left: 10px;
					color: $primary-color;
					cursor: pointer;
				}
			}
			.list-horizontal li:before {
				content: none;
				color: #ced4da;
				font-size: 20px;
			}

			.list-horizontal li:first-child:before {
				content: none;
			}

			.item-container {
				display: flex;
				min-width: 0px;
				background-color: #f8f8f8;
				border-radius: 2px;
				margin: 2px;
				box-sizing: border-box;
				border: 1px solid #ecf3fa;
				border-radius: 4px;
			}
		}
	}
}
