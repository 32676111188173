.creacion-manual-img {
	justify-content: center;
	width: 410px;
	height: 250px;
	background: url(./images/CreacionManualGris.svg) no-repeat center center/cover;

	&:hover {
		cursor: pointer;
		background: url(./images/CreacionManualColor.svg) no-repeat center center/cover;
	}
}

.registrar-credencial-img {
	align-items: center;
	width: 410px;
	height: 250px;
	background: url(./images/RegistrarCredencialGris.svg) no-repeat center center/cover;

	&:hover {
		cursor: pointer;
		background: url(./images/RegistrarCredencialColor.svg) no-repeat center center/cover;
	}
}

.form-file-upload {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	.label-file-upload {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		border: 2px dashed #f04a5e;
		padding: 24px 12px;
		border-radius: 0.5rem;
		gap: 16px;
		background-color: #fff1f1;

		&.drag-active {
			box-shadow: 8px rgba(0, 0, 0, 0.25);
		}
	}
}

.front-image {
	justify-content: center;
	width: 410px;
	height: 250px;
	background: url(./images/FrenteCredencial.svg) no-repeat center center/cover;
}

.back-image {
	justify-content: center;
	width: 410px;
	height: 250px;
	background: url(./images/ReversoCredencial.svg) no-repeat center center/cover;
}

.progress-bar {
	background-color: #f04a5e;
}

.ocr-data-row {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	gap: 8px;

	.ocr-data-column {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: 4px;
		border-bottom: 1px solid #cecccc;
		flex-grow: 1;
		flex: 1;

		.ocr-data-title {
			font-size: 10px;
			font-weight: 500;
			line-height: 15px;
			color: #f04a5e;
		}

		.ocr-data-names {
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
		}

		.ocr-data-info {
			font-size: 12px;
			font-weight: 400;
			line-height: 18px;
		}
	}
}
