.pyramids-header {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	gap: 8px;

	width: 100%;
	height: 100px;

	font-weight: 400;
	font-size: 14px;
	line-height: 17px;

	.header-options {
		display: flex;
		gap: 8px;
		max-height: 35px;

		.search-bar {
			display: flex;
			align-items: center;
			position: relative;
			flex-wrap: nowrap;
			input {
				height: 100%;
				padding-left: 40px !important;
			}
			svg {
				position: absolute;
				z-index: 99;
				left: 14px;
			}
			button {
				border-radius: 0px 6px 6px 0px;
			}
		}
	}

	button {
		display: flex;
		align-items: center;
		gap: 10px;
		white-space: nowrap;
		height: 100%;
	}
}
