@import 'assets/scss/utils/_variables.scss';
@import 'assets/scss/utils/_utilities.scss';

.tabsContainer {
	background-color: white;

	&.grayBackground {
		background-color: #f8f8f8;
	}
	&.nav-tabs {
		border-bottom: 3px solid #fff1f1;
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		flex-wrap: nowrap;

		.nav-link {
			color: #afafaf;
			padding: 20px;
			font-size: 1rem;
			font-weight: 500;
			border: none;
			white-space: nowrap;
			text-overflow: ellipsis;
			cursor: pointer;

			&.small {
				padding: 15px;
				font-size: 0.9rem;
			}

			&.active {
				color: $primary-color-brand;
				border-bottom: 3px solid $primary-color-brand;
			}
		}
	}
}

.tabContentContainer {
	background: white;
	padding: 26px 16px;

	&.noPadding {
		padding: 5px 0;
	}
}
