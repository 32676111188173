.imageEditor {
	display: flex;
	justify-content: center;
}

.controlsBlock {
	margin-top: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 1rem;
}

.scaleFieldBlock {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
}

input[type='range'] {
	-webkit-appearance: none; /* Remove default styling */
	width: 100%; /* Full width */
	height: 8px; /* Adjust as needed */
	background-color: #d3d3d3; /* Track color */
	border: 1px solid #ccc; /* Track border */
	border-radius: 16px;
}

input[type='range']::-webkit-slider-thumb {
	-webkit-appearance: none; /* Remove default styling */
	width: 16px; /* Thumb width */
	height: 16px; /* Thumb height */
	background-color: #f04a5e; /* Thumb color */
	border: 1px solid #f04a5e; /* Thumb border */
	border-radius: 50%; /* Rounded thumb */
	box-shadow: 0 0 1px #2196f3; /* Shadow effect */
}
